import {CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import './CustomerDetailPage.css';
import { Add } from '@mui/icons-material';

function Customers(props) {
  return (
    <div>
    <div style={{height: "calc(100vh - 64px - 35px - 35px - 35px)", display: "flex", flexDirection: "column", margin:"35px", borderRadius: "24px", padding: "16px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", background:"white"}}>
      <div style={{ width: "100%", borderRadius: "16px 16px 0px 0px" }}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <div style={{display: "flex"}}>
            <Typography variant="h6" style={{ padding: "16px" }}>Kunden</Typography>
            <TextField size='small' autoComplete='off' label="Suchen" style={{alignSelf: "center"}} defaultValue={props.filterText} onChange={(e) => props.onSearch(e.target.value)}/>
          </div>
          <Tooltip title="Neuer Kunde"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => props.createNewCustomer()}><Add/></IconButton></Tooltip>
        </div>
        <TableContainer style={{borderRadius: "16px 16px 0px 0px", height: "calc(100vh - 230px)", backgroundColor: "white"}}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 20 }}>Vorname</TableCell>
                <TableCell style={{ minWidth: 20 }}>Nachname</TableCell>
                <TableCell style={{ minWidth: 20 }}>Telefon</TableCell>
                <TableCell style={{ minWidth: 20 }}>Mobil</TableCell>
                <TableCell style={{ minWidth: 20 }}>E-Mail</TableCell>
              </TableRow>
            </TableHead>
            {/*Only show spinner if customers from rest not loaded*/}
            {props.customers != undefined ?
              <TableBody>
              {props.filteredCustomers
                .map((customer) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={customer.guid} onClick={() => props.onClick(customer)} style={{cursor: "pointer"}}>
                      <TableCell>
                        {customer.firstname}
                      </TableCell>
                      <TableCell>
                        {customer.lastname}
                      </TableCell>
                      <TableCell>
                        {customer.phonenumber}
                      </TableCell>
                      <TableCell>
                        {customer.mobilenumber}
                      </TableCell>
                      <TableCell>
                        {customer.email}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody> :
              <TableBody>
                <tr><td colSpan={5}><CircularProgress style={{display: "flex", margin: "auto", marginTop: "16px"}}/></td></tr>
              </TableBody>
            }
          </Table>
        </TableContainer>
        
      </div>
    </div>
    </div>
  );
}

export default Customers;