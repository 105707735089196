import { AppBar, Button, Container, Toolbar } from '@mui/material';
import './App.css';
import Logo from "./Assets/Logo.png";
import CustomerDetailPage from './Modules/CustomerDetailPage';
import Customers from './Modules/Customers';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

function App() {
  const [customers, setCustomers] = useState(undefined);
  const [filterText, setFilterText] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);

  const onClick = (selectedCustomer) => {
    setSelectedCustomer(selectedCustomer)
  }

  const onSearch = (value) => {
    setFilterText(value);
    if(value === "") {
      setFilteredCustomers(customers);
    } else {
      const filter = value.toUpperCase();
      const filteredData = customers.filter(row =>
        Object.values(row).some(cell =>
          cell != undefined ?  cell != "" ? cell.toString().toUpperCase().includes(filter) : false : false
        )
      );
      setFilteredCustomers(filteredData);
    }
  }

  const createNewCustomer = () => {
    setSelectedCustomer({
      newCustomer: true,
      firstname: "",
      lastname: "",
      guid: uuidv4()
    })
  }

  const onBack = (selectedCustomer) => {
    setSelectedCustomer(undefined)
  }

  const onSave = (selectedCustomer) => {
    setSelectedCustomer(undefined)
    loadCustomers()
  }

  const loadCustomers = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/api/v1/getCustomers',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1', 
        'Content-Type': 'multipart/form-data',
      }
    };

    axios.request(config)
    .then((response) => {
      setCustomers(response.data);
      setFilteredCustomers(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    loadCustomers();
  }, [])

  return (
    <div>
      <AppBar position="static" style={{ background: '#7d7d7d' }}>
        <Container maxWidth="sx">
          <Toolbar disableGutters>
            <a href={"/"} style={{ height: "48px", display: "flex", marginLeft: "0px", marginRight: "auto" }}><img src={Logo} alt="Logo"/></a>
            <Button variant='contained' onClick={() => window.location.replace('/')}>Kundendaten</Button>
            &nbsp; &nbsp;
            <Button variant='contained' onClick={() => window.location.replace('/time')}>Zeiterfassung</Button>
            &nbsp; &nbsp;
            <Button variant='contained' onClick={() => window.location.replace('/logout')}>Abmelden</Button>
          </Toolbar>
        </Container>
      </AppBar>
      {selectedCustomer === undefined ? 
      <Customers customers={customers} filterText={filterText} filteredCustomers={filteredCustomers} onClick={onClick} createNewCustomer={createNewCustomer} onSearch={onSearch}/> : 
      <CustomerDetailPage customer={selectedCustomer} onBack={onBack} onSave={onSave}/>}
    </div>
  );
}

export default App;