import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import { TimePicker } from '@mui/x-date-pickers';

export default function BasicTimePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <DemoContainer components={['DatePicker']} sx={{pt: 0}}>
        <TimePicker
          disabled={props.disabled}
          slotProps={
            {
              textField: { 
                size: "small", 
                variant: "outlined", 
                style: {overflow: "hidden", minWidth: "105px"}
              },
            }
          }
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}