import { AppBar, Button, Container, Toolbar } from '@mui/material';
import './App.css';
import Logo from "./Assets/Logo.png";
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import TimeTrackerUserList from './Modules/TimetrackerUserList';
import EmployeeTimeTrackingPage from './Modules/EmployeeTimeTrackingPage';

function App() {
  const [employees, setEmployees] = useState(undefined);
  const [filterText, setFilterText] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState(employees);
  const [selecetedEmployee, setSelectedEmployee] = useState(undefined);

  const onClick = (selecetedEmployee) => {
    setSelectedEmployee(selecetedEmployee)
  }

  const onSearch = (value) => {
    setFilterText(value);
    if(value === "") {
      setFilteredEmployees(employees);
    } else {
      const filter = value.toUpperCase();
      const filteredData = employees.filter(row =>
        Object.values(row).some(cell =>
          cell != undefined ?  cell != "" ? cell.toString().toUpperCase().includes(filter) : false : false
        )
      );
      setFilteredEmployees(filteredData);
    }
  }

  const createNewEmployee = () => {
    setSelectedEmployee({
      newEmployee: true,
      firstname: "",
      lastname: "",
      address: "",
      postalcode: "",
      city: "",
      phonenumber: "",
      mobilenumber: "",
      emergencyphonenumber: "",
      email: "",
      birthdate: "",
      type: "",
      type_2: "",
      guid: uuidv4()
    })
  }

  const onBack = (selecetedEmployee) => {
    setSelectedEmployee(undefined)
  }

  const onSave = (selecetedEmployee) => {
    setSelectedEmployee(undefined)
    loadEmployees()
  }

  const loadEmployees = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/api/v1/getEmployees',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1', 
        'Content-Type': 'multipart/form-data',
      }
    };

    axios.request(config)
    .then((response) => {
      setEmployees(response.data);
      setFilteredEmployees(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    loadEmployees();
  }, [])

  return (
    <div>
      <AppBar position="static" style={{ background: '#7d7d7d' }}>
        <Container maxWidth="sx">
          <Toolbar disableGutters>
            <a href={"/"} style={{ height: "48px", display: "flex", marginLeft: "0px", marginRight: "auto" }}><img src={Logo} alt="Logo"/></a>
            <Button variant='contained' onClick={() => window.location.replace('/')}>Kundendaten</Button>
            &nbsp; &nbsp;
            <Button variant='contained' onClick={() => window.location.replace('/time')}>Zeiterfassung</Button>
            &nbsp; &nbsp;
            <Button variant='contained' onClick={() => window.location.replace('/logout')}>Abmelden</Button>
          </Toolbar>
        </Container>
      </AppBar>
      {selecetedEmployee === undefined ? 
      <TimeTrackerUserList employee={employees} filterText={filterText} filteredEmployees={filteredEmployees} onClick={onClick} createNewEmployee={createNewEmployee} onSearch={onSearch}/> : 
      <EmployeeTimeTrackingPage employee={selecetedEmployee} onBack={onBack} onSave={onSave}/>}
    </div>
  );
}

export default App;