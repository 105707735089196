import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import './EmployeeTimeTrackingPage.css';
import { Add, ArrowBack, DeleteForever, Save } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import BasicDatePicker from './BasicDatePicker';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import BasicTimePicker from './BasicTimePicker';

function EmployeeDetailPage(props) {
  const [employeeDetails, setEmployeeDetails] = useState(undefined);
  const [firstname, setFirstname] = useState(props.employee.firstname);
  const [lastname, setLastname] = useState(props.employee.lastname);
  const [address, setAddress] = useState(props.employee.address);
  const [postalcode, setPostalcode] = useState(props.employee.postalcode);
  const [city, setCity] = useState(props.employee.city);
  const [phonenumber, setPhonenumber] = useState(props.employee.phonenumber);
  const [mobilenumber, setMobilenumber] = useState(props.employee.mobilenumber);
  const [emergencyphonenumber, setEmergencyPhonenumber] = useState(props.employee.emergencyphonenumber);
  const [email, setEmail] = useState(props.employee.email);
  const [birthdate, setBirthdate] = useState(props.employee.birthdate);
  const [type, setType] = useState(props.employee.type);
  const [type_2, setType_2] = useState(props.employee.type_2);

  const [open, setOpen] = useState(false);
  const [deleteIdx, setDeleteIdx] = useState(undefined);

  const handleClickOpen = (idx) => {
    setOpen(true);
    setDeleteIdx(idx);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteRow(deleteIdx);
    setDeleteIdx(undefined);
    setOpen(false);
  };


  const onSave = (selectedEmployee) => {
    if(props.employee.newEmployee === true) {
      createEmployee();
    } else {
      saveEmployee();
    }
  }

  const createNewRow = () => {
    var newDate = new Date();
    var newRow = {
      "date": newDate.toISOString(),
      "start": "",
      "pause_start": "",
      "pause_end": "",
      "end": "",
      "comment": "",
      "new_entry": true,
      "employee_guid": props.employee.guid,
      "guid": uuidv4()
    };
    setEmployeeDetails([...employeeDetails, newRow]);
  }

  const deleteRow = (idx) => {
    var newEmployeeDetails = [...employeeDetails];
    newEmployeeDetails.splice(idx, 1);
    setEmployeeDetails(newEmployeeDetails);
  }

  const onTableDateChange = (idx, value) => {
    var newEmployeeDetails = employeeDetails;
    newEmployeeDetails[idx].date = value;
    setEmployeeDetails(newEmployeeDetails);
  }

  const onTableStartChange = (idx, value) => {
    var newEmployeeDetails = employeeDetails;
    newEmployeeDetails[idx].start = value;
    setEmployeeDetails(newEmployeeDetails);
  }

  const onTablePauseStartChange = (idx, value) => {
    var newEmployeeDetails = employeeDetails;
    newEmployeeDetails[idx].pause_start = value;
    setEmployeeDetails(newEmployeeDetails);
  }

  const onTablePauseEndChange = (idx, value) => {
    var newEmployeeDetails = employeeDetails;
    newEmployeeDetails[idx].pause_end = value;
    setEmployeeDetails(newEmployeeDetails);
  }

  const onTableEndChange = (idx, value) => {
    var newEmployeeDetails = employeeDetails;
    newEmployeeDetails[idx].end = value;
    setEmployeeDetails(newEmployeeDetails);
  }

  const onTableCommentChange = (idx, value) => {
    var newEmployeeDetails = employeeDetails;
    newEmployeeDetails[idx].comment = value;
    setEmployeeDetails(newEmployeeDetails);
  }


  const createEmployee = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('firstname', firstname);
    data.append('lastname', lastname);
    data.append('address', address);
    data.append('postalcode', postalcode);
    data.append('city', city);
    data.append('phonenumber', phonenumber);
    data.append('mobilenumber', mobilenumber);
    data.append('emergencyphonenumber', emergencyphonenumber);
    data.append('email', email);
    data.append('birthdate', birthdate);
    data.append('type', type);
    data.append('type_2', type_2);
    data.append('guid', props.employee.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/createEmployee',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      saveEmployeeDetails();
      props.onSave(undefined);
    })
    .catch((error) => {
      console.log(error);
    });
  }


  const saveEmployee = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('firstname', firstname);
    data.append('lastname', lastname);
    data.append('address', address);
    data.append('postalcode', postalcode);
    data.append('city', city);
    data.append('phonenumber', phonenumber);
    data.append('mobilenumber', mobilenumber);
    data.append('emergencyphonenumber', emergencyphonenumber);
    data.append('email', email);
    data.append('birthdate', birthdate);
    data.append('type', type);
    data.append('type_2', type_2);
    data.append('guid', props.employee.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/editEmployee',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      saveEmployeeDetails();
    })
    .catch((error) => {
      console.log(error);
    });
  }


  const saveEmployeeDetails = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('entries', JSON.stringify(employeeDetails));
    data.append('employee_guid', props.employee.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/crudEntriesForEmployee',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      props.onSave(undefined);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const loadEmployeeDetails = (employee) => {
    let data = new FormData();
    data.append('employee_guid', employee.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/getEntriesForEmployee',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1', 
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      console.log(response.data)
      setEmployeeDetails(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    loadEmployeeDetails(props.employee);
  }, [props.employee])

  return (
    <div style={{height: "calc(100vh - 64px - 35px - 35px - 35px)", margin:"35px", borderRadius: "24px", padding: "16px", display: "flex", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", background:"white"}}>
      <div style={{width: "100%"}}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div style={{ display: "flex", justifyContent: "start"}}>
            <Tooltip title="Zurück"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => props.onBack()}><ArrowBack/></IconButton></Tooltip>
            <Typography style={{alignSelf: "center"}} variant='h6'>{lastname !== "" ? lastname + ", " + firstname : firstname}</Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "end"}}>
            <Tooltip title="Neuer Eintrag"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => createNewRow()}><Add/></IconButton></Tooltip>
            <Tooltip title="Speichern"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => onSave()}><Save/></IconButton></Tooltip>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{ width: "50%", overflow: "scroll"}}>
            <Typography variant="h6" style={{padding: "16px"}}>Mitarbeiter*in</Typography>
            <div style={{display: "flex", height: "calc(100svh - 297px)", overflowX: "scroll", flexDirection: "column"}}>
              <TextField label="Vorname" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.firstname} onChange={(e) => setFirstname(e.target.value)}></TextField>
              <TextField label="Nachname" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.lastname} onChange={(e) => setLastname(e.target.value)}></TextField>
              <TextField label="Adresse" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.address} onChange={(e) => setAddress(e.target.value)}></TextField>
              <TextField label="Telefon" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.phonenumber} onChange={(e) => setPhonenumber(e.target.value)}></TextField>
              <TextField label="Mobil" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.mobilenumber} onChange={(e) => setMobilenumber(e.target.value)}></TextField>
              <TextField label="Notfallkontakt" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.emergencyphonenumber} onChange={(e) => setEmergencyPhonenumber(e.target.value)}></TextField>
              <TextField label="Geburtstag" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.birthdate} onChange={(e) => setBirthdate(e.target.value)}></TextField>
              <TextField label="E-Mail" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.email} onChange={(e) => setEmail(e.target.value)}></TextField>
              <TextField label="Stadt" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.city} onChange={(e) => setCity(e.target.value)}></TextField>
              <TextField label="PLZ" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.postalcode} onChange={(e) => setPostalcode(e.target.value)}></TextField>
              <TextField label="Kennung" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.type} onChange={(e) => setType(e.target.value)}></TextField>
              <TextField label="Kennung 2" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.employee.type_2} onChange={(e) => setType_2(e.target.value)}></TextField>
            </div>
          </div>
          <div style={{ width: "100%", paddingLeft: "8px"}}>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
              <Typography variant="h6" style={{ padding: "16px" }}>Einträge</Typography>
            </div>
            <TableContainer style={{height: "calc(100svh - 297px)", backgroundColor: "white"}}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={"5%"}>Datum</TableCell>
                    <TableCell width={"5%"}>Start-Zeit</TableCell>
                    <TableCell width={"5%"}>Pause-Start</TableCell>
                    <TableCell width={"5%"}>Pause-Ende</TableCell>
                    <TableCell width={"5%"}>End-Zeit</TableCell>
                    <TableCell width={"75%"}>Kommentar</TableCell>
                  </TableRow>
                </TableHead>
                {/*Only show spinner if employee details from rest not loaded*/}
                {employeeDetails !== undefined ?
                  <TableBody>
                  {employeeDetails.map((row, idx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.guid}>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <BasicDatePicker disabled={!row.new_entry === true} defaultValue={dayjs(row.date)} onChange={(newValue) => onTableDateChange(idx, newValue.toISOString())}/>
                        </TableCell>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <BasicTimePicker disabled={!row.new_entry === true} defaultValue={dayjs(row.start)} onChange={(newValue) => onTableStartChange(idx, newValue.toISOString())}/>
                        </TableCell>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <BasicTimePicker disabled={!row.new_entry === true} defaultValue={dayjs(row.pause_start)} onChange={(newValue) => onTablePauseStartChange(idx, newValue.toISOString())}/>
                        </TableCell>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <BasicTimePicker disabled={!row.new_entry === true} defaultValue={dayjs(row.pause_end)} onChange={(newValue) => onTablePauseEndChange(idx, newValue.toISOString())}/>
                        </TableCell>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <BasicTimePicker disabled={!row.new_entry === true} defaultValue={dayjs(row.end)} onChange={(newValue) => onTableEndChange(idx, newValue.toISOString())}/>
                        </TableCell>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <div style={{display: "flex"}}>
                            <TextField disabled={!row.new_entry === true} size='small' variant='outlined' defaultValue={row.comment} style={{width: "100%"}} onChange={(e) => onTableCommentChange(idx, e.target.value)}/>
                            <IconButton style={{marginLeft: "6px"}} onClick={(e) => handleClickOpen(idx)}><DeleteForever/></IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  </TableBody> :
                  <TableBody>
                    <tr><td colSpan={6}><CircularProgress style={{display: "flex", margin: "auto", marginTop: "16px"}}/></td></tr>
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eintrag löschen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Der Eintrag wird permanent gelöscht.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button onClick={handleDelete} autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EmployeeDetailPage;