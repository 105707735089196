import { ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import MainView from './MainView';
import MainViewTime from './MainView_Time';
import SignIn from './Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const defaultTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4A3A2A',
    },
    secondary: {
      main: '#f50057',
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<MainView/>}/>
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/time">
        <Routes>
          <Route path="/" element={<MainViewTime/>}/>
        </Routes>
      </BrowserRouter>
      <BrowserRouter basename="/login">
        <Routes>
          <Route path="/" element={<SignIn/>}/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;