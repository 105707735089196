import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Logo from "./Assets/Logo.png";
import "./Login.css";
import Cookies from 'js-cookie';

async function loginUser(credentials) {
  console.log(Cookies.get('csrftoken'))
  return fetch('/login', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    credentials: 'include',
    mode: 'same-origin',
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function SignIn() {  
  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();

  const handleSubmit = async e => {
    e.preventDefault();
    await loginUser({
      username,
      password
    });
  }

  return (
    <div class="anim_gradient"> 
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box 
          style={{ padding:"45px", borderRadius:"32px",boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",  background:"white"}}
          sx={{ 
            marginTop:"20%",
            marginBottom:"50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <center><img src={Logo} alt='Scherer-Logo' width={"70%"} /></center>
          <br></br>  
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={() => handleSubmit()}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Benutzername"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}