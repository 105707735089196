import {CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import './CustomerDetailPage.css';
import { Add, ArrowBack, Save } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import BasicDatePicker from './BasicDatePicker';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

function CustomerDetailPage(props) {
  const [customerDetails, setCustomerDetails] = useState(undefined);
  const [firstname, setFirstname] = useState(props.customer.firstname);
  const [lastname, setLastname] = useState(props.customer.lastname);
  const [address, setAddress] = useState(props.customer.address);
  const [postalcode, setPostalcode] = useState(props.customer.postalcode);
  const [city, setCity] = useState(props.customer.city);
  const [phonenumber, setPhonenumber] = useState(props.customer.phonenumber);
  const [mobilenumber, setMobilenumber] = useState(props.customer.mobilenumber);
  const [email, setEmail] = useState(props.customer.email);
  const [birthdate, setBirthdate] = useState(props.customer.birthdate);
  const [type, setType] = useState(props.customer.type);
  const [type_2, setType_2] = useState(props.customer.type_2);

  const onSave = (selectedCustomer) => {
    if(props.customer.newCustomer === true) {
      createCustomer();
    } else {
      saveCustomer();
    }
  }

  const createNewRow = () => {
    var newDate = new Date();

    var newRow = {
      "date": newDate.toISOString(),
      "value": "",
      "customer_guid": props.customer.guid,
      "guid": uuidv4()
    };
    setCustomerDetails([...customerDetails, newRow]);
  }

  const onTableDateChange = (idx, value) => {
    var newCustomerDetails = customerDetails;
    newCustomerDetails[idx].date = value;
    setCustomerDetails(newCustomerDetails);
  }

  const onTableValueChange = (idx, value) => {
    var newCustomerDetails = customerDetails;
    newCustomerDetails[idx].value = value;
    setCustomerDetails(newCustomerDetails);
  }


  const createCustomer = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('firstname', firstname);
    data.append('lastname', lastname);
    data.append('address', address);
    data.append('postalcode', postalcode);
    data.append('city', city);
    data.append('phonenumber', phonenumber);
    data.append('mobilenumber', mobilenumber);
    data.append('email', email);
    data.append('birthdate', birthdate);
    data.append('type', type);
    data.append('type_2', type_2);
    data.append('guid', props.customer.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/createCustomer',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      saveCustomerDetails();
      props.onSave(undefined);
    })
    .catch((error) => {
      console.log(error);
    });
  }


  const saveCustomer = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('firstname', firstname);
    data.append('lastname', lastname);
    data.append('address', address);
    data.append('postalcode', postalcode);
    data.append('city', city);
    data.append('phonenumber', phonenumber);
    data.append('mobilenumber', mobilenumber);
    data.append('email', email);
    data.append('birthdate', birthdate);
    data.append('type', type);
    data.append('type_2', type_2);
    data.append('guid', props.customer.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/editCustomer',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      saveCustomerDetails();
    })
    .catch((error) => {
      console.log(error);
    });
  }


  const saveCustomerDetails = () => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('entries', JSON.stringify(customerDetails));
    data.append('customer_guid', props.customer.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/crudEntriesForCustomer',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      props.onSave(undefined);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const loadCustomerDetails = (customer) => {
    let data = new FormData();
    data.append('customer_guid', customer.guid);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/v1/getEntriesForCustomer',
      headers: { 
        'Authorization': 'Basic a2V2aW5qYWtvYjpHN18wM18yMDE1', 
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setCustomerDetails(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    loadCustomerDetails(props.customer);
  }, [props.customer])

  return (
    <div style={{height: "calc(100vh - 64px - 35px - 35px - 35px)", margin:"35px", borderRadius: "24px", padding: "16px", display: "flex", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", background:"white"}}>
      <div style={{width: "100%"}}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div style={{ display: "flex", justifyContent: "start"}}>
            <Tooltip title="Zurück"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => props.onBack()}><ArrowBack/></IconButton></Tooltip>
            <Typography style={{alignSelf: "center"}} variant='h6'>{lastname !== "" ? lastname + ", " + firstname : firstname}</Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "end"}}>
            <Tooltip title="Neuer Eintrag"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => createNewRow()}><Add/></IconButton></Tooltip>
            <Tooltip title="Speichern"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => onSave()}><Save/></IconButton></Tooltip>
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div style={{ width: "50%", overflow: "scroll"}}>
            <Typography variant="h6" style={{padding: "16px"}}>Kundendaten</Typography>
            <div style={{display: "flex", height: "calc(100svh - 297px)", overflowX: "scroll", flexDirection: "column"}}>
              <TextField label="Vorname" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.firstname} onChange={(e) => setFirstname(e.target.value)}></TextField>
              <TextField label="Nachname" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.lastname} onChange={(e) => setLastname(e.target.value)}></TextField>
              <TextField label="Adresse" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.address} onChange={(e) => setAddress(e.target.value)}></TextField>
              <TextField label="Telefon" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.phonenumber} onChange={(e) => setPhonenumber(e.target.value)}></TextField>
              <TextField label="Mobil" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.mobilenumber} onChange={(e) => setMobilenumber(e.target.value)}></TextField>
              <TextField label="Geburtstag" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.birthdate} onChange={(e) => setBirthdate(e.target.value)}></TextField>
              <TextField label="E-Mail" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.email} onChange={(e) => setEmail(e.target.value)}></TextField>
              <TextField label="Stadt" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.city} onChange={(e) => setCity(e.target.value)}></TextField>
              <TextField label="PLZ" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.postalcode} onChange={(e) => setPostalcode(e.target.value)}></TextField>
              <TextField label="Kennung" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.type} onChange={(e) => setType(e.target.value)}></TextField>
              <TextField label="Kennung 2" size="small" style={{margin: "8px"}} autoComplete='off' defaultValue={props.customer.type_2} onChange={(e) => setType_2(e.target.value)}></TextField>
            </div>
          </div>
          <div style={{ width: "100%", paddingLeft: "8px"}}>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
              <Typography variant="h6" style={{ padding: "16px" }}>Einträge</Typography>
            </div>
            <TableContainer style={{height: "calc(100svh - 297px)", backgroundColor: "white"}}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={"5%"}>Datum</TableCell>
                    <TableCell width={"95%"}>Kommentar</TableCell>
                  </TableRow>
                </TableHead>
                {/*Only show spinner if customer details from rest not loaded*/}
                {customerDetails !== undefined ?
                  <TableBody>
                  {customerDetails.map((row, idx) => {
                    var date = dayjs(row.date);
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.guid}>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <BasicDatePicker style={{width: "8ß%"}} defaultValue={date} onChange={(newValue) => onTableDateChange(idx, newValue.toISOString())}/>
                        </TableCell>
                        <TableCell style={{paddingLeft: "6px", paddingRight: "6px"}}>
                          <TextField size='small' variant='outlined' defaultValue={row.value} style={{width: "100%"}} onChange={(e) => onTableValueChange(idx, e.target.value)}/>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  </TableBody> :
                  <TableBody>
                    <tr><td colSpan={2}><CircularProgress style={{display: "flex", margin: "auto", marginTop: "16px"}}/></td></tr>
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetailPage;