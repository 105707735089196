import {CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import './TimetrackerUserList.css';
import { Add } from '@mui/icons-material';

function TimeTrackerUserList(props) {
  return (
    <div>
    <div style={{height: "calc(100vh - 64px - 35px - 35px - 35px)", display: "flex", flexDirection: "column", margin:"35px", borderRadius: "24px", padding: "16px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", background:"white"}}>
      <div style={{ width: "100%", borderRadius: "16px 16px 0px 0px" }}>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
          <div style={{display: "flex"}}>
            <Typography variant="h6" style={{ padding: "16px" }}>Mitarbeiter*innen</Typography>
            <TextField size='small' autoComplete='off' label="Suchen" style={{alignSelf: "center"}} onChange={(e) => props.onSearch(e.target.value)}/>
          </div>
          <Tooltip title="Neue*r Mitarbeiter*in"><IconButton style={{ width: "48px", height: "48px", margin: "8px"}} onClick={() => props.createNewEmployee()}><Add/></IconButton></Tooltip>
        </div>
        <TableContainer style={{borderRadius: "16px 16px 0px 0px", height: "calc(100vh - 230px)", backgroundColor: "white"}}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 50 }}>Vorname</TableCell>
                <TableCell style={{ minWidth: 50 }}>Nachname</TableCell>
              </TableRow>
            </TableHead>
            {/*Only show spinner if customers from rest not loaded*/}
            {props.employee != undefined ?
              <TableBody>
              {props.filteredEmployees
                .map((employee) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={employee.code} onClick={() => props.onClick(employee)} style={{cursor: "pointer"}}>
                      <TableCell>
                        {employee.firstname}
                      </TableCell>
                      <TableCell>
                        {employee.lastname}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody> :
              <tr><td colSpan={5}><CircularProgress style={{display: "flex", margin: "auto", marginTop: "16px"}}/></td></tr>
            }
          </Table>
        </TableContainer>
        
      </div>
    </div>
    </div>
  );
}

export default TimeTrackerUserList;